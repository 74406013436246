.key-parameter {
    margin: 5% 0;
}

.keycard {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    position: relative;
    padding-top: 50px;
}


.fixback_div {
    width: 100%;
    height: 112px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.fixback_div .fix_img {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.keycard:hover .fixback_div .fix_img {
    opacity: 0.9;
}

.changes_mains {
    position: relative;
    padding-bottom: 60px;

}

.change_div {
    width: 117px;
    height: 117px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

}

.change_div .change_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.png_main_div {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #224299;
    position: absolute;
    top: 90px;
    left: calc(50% - 30px);
}

.pngsub_div {
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.keycard:hover .pngsub_div {
    background-color: #266DB9;
}


.png_div {
    max-width: 100%;
    height: 29px;
}

.png_div .png_img {
    height: 100%;
    width: auto;
    max-width: 100%;
}

.keycard:hover .png_div .png_img {
    filter: brightness(0) invert(1);
}


.qualitytext {
    text-align: center;
    font: normal normal medium 23px/30px Roboto;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
    padding-bottom: 37px;
}


@media (min-width:0px) and (max-width:767px) {
    .key-parameter {
        margin: 10% 0;
    }
}