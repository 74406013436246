.recent-news {
    margin: 20% 0 10% 0;
    position: sticky;
    top: 125px;
}

.recent-news .recent-title {
    font-size: 20px;
    color: #3B3B3B;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.recent-news .recent-img {
    width: 100%;
    height: 52px;
}

.recent-news .recent-news-blog-box {
    border: 0.5px solid #707070;
    border-radius: 5px;
    padding: 10px 10px 5px 10px;
    margin-top: 5%;
}

.recent-news .blog-card {
    margin: 10% 0;
}

.recent-news .card {
    border: none;
    border-radius: 0px;
}

.recent-news .double-txtt {
    font-weight: 600;
}

.recent-news .card .card-img-top {
    border-radius: 0;
}

.recent-news .card .card-body {
    background: #EEF4FD;
}

.recent-news .font-icon-main {
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    transition: background 0.ss ease;
    background: radial-gradient(circle, #FFFFFF 50%, #FFFFFF 50%);
}

.recent-news .sub-main-rececc:hover .font-icon-main {
    background: radial-gradient(circle, #224299 50%, #224299 50%);
    border: 1px solid #224299;
}

.recent-news .font-icon-main .font-icon {
    color: #224299;
    background: #DCF4FE;
    padding: 8px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: background 0.ss ease;
    background: radial-gradient(circle, #DCF4FE 50%, #DCF4FE 50%);
}

.recent-news .sub-main-rececc:hover .font-icon-main .font-icon {
    color: #FFF;
    background: radial-gradient(circle, #224299 50%, #224299 50%);
}

/* media */

@media(min-width:0px) and (max-width:576px) {
    .recent-news .recent-title {
        font-size: 16px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .recent-news .recent-title {
        font-size: 18px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .recent-news .recent-title {
        font-size: 18px;
    }
}