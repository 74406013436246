.Home-Banner img.Banner-image {
  height: auto;
  width: 100%;
}

.Home-Banner .main-banner-img-class {
  position: relative;
}

.Home-Banner .main-banner-img-class .btnn-read {
  position: absolute;
  top: 60%;
  left: 10%;
}