.main-blog .mark-img {
    width: 20px;
    height: 20px;
}

.main-blog  .blue-border {
    border-bottom: 3px solid #224299;

}

.main-blog .li {
    font-size: 18px;
    font-weight: 500;
} 