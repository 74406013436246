.waterdetails_main {
    position: relative;
    padding-top: 90px;
}

.waterdetails_main .blue-border {
    border-bottom: 3px solid #224299;
}

.waterdetails_main  .waterdiv_img{
    width: 100%;
    height: 168px;
    padding: 0px 10px;
    position: absolute;
    top:0;
    left: 0;
}
.waterdetails_main .waterdiv_img .water_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.waterdetails_main .textdiv_main{
    padding: 94px 10px 10px 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px #00000029;
}
.waterdetails_main .bottom_buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.waterdetails_main  .icnimg_div{
    height: 37px;
    width: auto;
}
.waterdetails_main .icnimg_div .icn_img{
    height: 100%;
    width: auto;
}
.waterdetails_main .ptext_div {
    height: 85px;
}
.waterdetails_main .ptext_div .sub-txt{
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  color: #3B3B3B;
}
.start-txttt {
    border-bottom: 3px solid #224299;
}
.waterdetails_main .systemtext{
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}