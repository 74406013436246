@import url("https://fonts.googleapis.com/css2?family=Italianno&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto" !important;
}

.common-btn {
  color: #FFF;
  font-size: 16px;
  background: #224299;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  position: relative;
  overflow: hidden;
}

.common-btn::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #266DB9;
  z-index: 1;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.5s ease-out;
}

.common-btn:hover::before {
  animation: corner-expand 0.5s forwards;
}

@keyframes corner-expand {
  0% {
    transform: scale(0);
    transform-origin: top right;
  }

  25% {
    transform: scaleX(1);
    transform-origin: top right;
  }

  50% {
    transform: scale(1);
    transform-origin: top right;
  }

  100% {
    transform: scale(1);
    transform-origin: bottom left;
  }
}

.common-btn .common-span {
  position: relative;
  z-index: 2;
}

.common-txt {
  font-size: 14px;
  color: #000;
}

.blog-common-title {
  font-size: 25px;
  font-weight: 700;
  color: #3B3B3B;
}

.sub-txt {
  font-size: 16px;
  color: #3B3B3B;
  text-align: justify;
}

.midtext {
  font-size: 20px;
  font-weight: 600;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


@media(min-width:0px) and (max-width:576px) {
  .sub-txt {
    font-size: 14px;
  }

  .common-txt {
    font-size: 13px;
  }

  .blog-common-title {
    font-size: 18px;
  }

  .common-btn {
    font-size: 10px;
    padding: 6px 8px;
  }

  .midtext {
    font-size: 16px;


  }
}


@media(min-width:577px) and (max-width:767px) {
  .sub-txt {
    font-size: 14px;
  }

  .common-txt {
    font-size: 13px;
  }

  .blog-common-title {
    font-size: 17px;
  }

  .common-btn {
    font-size: 14px;
  }

  .midtext {
    font-size: 17px;


  }
}

@media(min-width:768px) and (max-width:992px) {
  .sub-txt {
    font-size: 14px;
  }

  .common-txt {
    font-size: 13px;
  }

  .blog-common-title {
    font-size: 20px;
  }

  .common-btn {
    font-size: 14px;
  }

  .midtext {
    font-size: 18px;
  }
}