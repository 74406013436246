.bottle-deliver {
    margin: 5% 0;
}

.bottle-deliver .bottle-compo {
    width: 100%;

}



/* second */

.bottle_section .bottlemain {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 15px 35px;
}

.bottle_section .bootleimg_div {
    height: 100px;
    width: fit-content;
    max-width: 100%;
    margin-left: auto;
    padding-right: 50px;
}

.bottle_section .bootleimgs {
    width: auto;
    max-width: 100%;
    height: 100%;

}

.bottle_section .bottmtex_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottle_section .botltext {
    padding-left: 50px;
    border-left: 1px solid #224299;
}

/* .bottle-deliver .we-deliver-firsttt {
    overflow-x: scroll;
    height: 500px;
    padding: 20px;
} */

@media(min-width:0px) and (max-width:320px) {
    .bottle_section .bootleimg_div {
        padding-right: 0px;
    }

    .bottle_section .botltext {
        padding-left: 15px;
    }

    .bottle-deliver .bottle-compo {
        aspect-ratio: unset;
    }

    .bottle-deliver {
        margin: 10% 0;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .bottle_section .bootleimg_div {
        padding-right: 10px;
    }

    .bottle_section .botltext {
        padding-left: 25px;
    }

    .bottle-deliver .bottle-compo {
        aspect-ratio: unset;
    }

    .bottle-deliver {
        margin: 10% 0;
    }
}

@media(min-width:486px) and (max-width:575px) {
    .bottle_section .bootleimg_div {
        padding-right: 20px;
    }

    .bottle_section .botltext {
        padding-left: 25px;
    }

    .bottle-deliver .bottle-compo {
        aspect-ratio: unset;
    }

    .bottle-deliver {
        margin: 10% 0;
    }
}


@media(min-width:577px) and (max-width:767px) {
    .bottle-deliver .bottle-compo {
        aspect-ratio: unset;
    }

    .bottle-deliver {
        margin: 10% 0;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .bottle_section .bootleimg_div {
        padding-right: 10px;
    }

    .bottle_section .botltext {
        padding-left: 15px;
    }

    .bottle-deliver .bottle-compo {
        aspect-ratio: unset;
    }
}