
.counters_section{
    background: #DCF4FE 0% 0% no-repeat padding-box;
padding: 20px 0px;
}
.conuts_main_div{
    text-align: center;
font-size: 40px;
font-weight: bold;
letter-spacing: 0px;
color: #224299;

}

.counters_section .cunttxt{
    color: #224299;
    font-weight: 500;
}
.counters_section  .leftborder{
    border-right: 1px solid #777777;
}

@media(min-width:0px) and (max-width:575px){
    .conuts_main_div{
    font-size: 25px;
   
    
    }
    .counters_section  .leftborder{
        border-right: none;
    }
    
}
@media(min-width:576px) and (max-width:768px){
    .conuts_main_div{
    font-size: 28px;
    }
}