.review-home {
    margin: 5% 0;
}

.review-home .video-div .video-review {
    width: 100%;
    height: 100%;
}

.review-home .blue-bggggg {
    background: #EEF4FD;
    height: 100%;
}

.review-home .double-comma {
    font-size: 250px;
    color: #DCEDFF;
}


.review-home .silder-btn {
    position: relative;

}

.review-home .silder-btn .back-btn {
    position: absolute;
    top: -280px;
    z-index: 999;
    left: 0;
}

.review-home .silder-btn .next-btn {
    position: absolute;
    top: -280px;
    z-index: 999;
    right: 433px;
}

.review-home .silder-btn .icon-btnnn {
    color: #224299;
    font-weight: bold;
    font-size: 28px;
    background: #FFF;
    border: 3px solid #224299;
    border-radius: 50%;
    padding: 4px 9px;
}

.review-home .silder-btn .back-btn:hover .icon-btnnn {
    border: 5px solid #224299;
    font-size: 25px;
}

.review-home .silder-btn .next-btn:hover .icon-btnnn {
    border: 5px solid #224299;
    font-size: 25px;
}

/* Media */

@media(min-width:0px) and (max-width:320px) {
    .review-home .double-comma {
        font-size: 90px;
    }

    .review-home .silder-btn .back-btn {
        top: -222px;
    }

    .review-home .silder-btn .next-btn {
        top: -222px;
        right: 0;
    }

    .review-home {
        margin: 13% 0;
    }
}

@media(min-width:321px) and (max-width:420px) {
    .review-home .double-comma {
        font-size: 90px;
    }

    .review-home .silder-btn .back-btn {
        top: -222px;
    }

    .review-home .silder-btn .next-btn {
        top: -222px;
        right: 0;
    }

    .review-home {
        margin: 13% 0;
    }
}

@media(min-width:421px) and (max-width:576px) {
    .review-home .double-comma {
        font-size: 90px;
    }

    .review-home .silder-btn .back-btn {
        top: -222px;
    }

    .review-home .silder-btn .next-btn {
        top: -222px;
        right: 0;
    }

    .review-home {
        margin: 13% 0;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .review-home .double-comma {
        font-size: 90px;
    }

    .review-home .silder-btn .back-btn {
        top: -222px;
    }

    .review-home .silder-btn .next-btn {
        top: -222px;
        right: 0;
    }
}

@media(min-width:567px) and (max-width:768px) {
    .review-home .double-comma {
        font-size: 90px;
    }

    .review-home .silder-btn .back-btn {
        top: -126px;
    }

    .review-home .silder-btn .next-btn {
        top: -126px;
        right: 0;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .review-home .double-comma {
        font-size: 109px;
        color: #DCEDFF;
    }

    .review-home .silder-btn .back-btn {
        top: -169px;
    }

    .review-home .silder-btn .next-btn {
        top: -169px;
        right: 233px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .review-home .double-comma {
        font-size: 168px;
    }

    .review-home .silder-btn .back-btn {
        top: -205px;
    }

    .review-home .silder-btn .next-btn {
        top: -205px;
        right: 313px;
    }

}

@media(min-width:1201px) and (max-width:1399px) {

    .review-home .silder-btn .back-btn {
        top: -205px;
    }

    .review-home .silder-btn .next-btn {
        top: -205px;
        right: 373px;
    }

}