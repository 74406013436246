.blog-banner-details .main-banner-class {
    position: relative;
}

.blog-banner-details .main-banner-class .blog-details-banner {
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.blog-banner-details .main-banner-class .back-btn {
    position: absolute;
    top: 20px;
    border: none;
    background: transparent;
    left: 10%;
    font-size: 16px;
    font-weight: 700;
    color: #224299;
}


@media (min-width:0px) and (max-width:576px) {
    .blog-banner-details .main-banner-class .back-btn {
        position: unset;
        margin: 3% 0;
        font-size: 14px;
    }
}

@media (min-width:577px) and (max-width:768px) {
    .blog-banner-details .main-banner-class .back-btn {
        top: 12px;
        font-size: 13px;
    }
}