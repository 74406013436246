@import url('https://fonts.googleapis.com/css2?family=Laila:wght@300;400;500;600;700&display=swap');

.home-about-us {
    margin: 5% 0;
}

.home-common-title {
    font-size: 32px;
    color: #224299;
    font-family: "Laila", serif;
    font-weight: 700;
    font-style: normal;
}

.home-common-title .start-txttt {
    border-bottom: 3px solid #224299;
}

.sub-txtttt-spring {
    font-size: 20px;
    color: #3B3B3B;
    font-weight: 600;
}

.home-about-us .blue-border {
    border-bottom: 1px solid #224299;
}

.home-about-us .main-light-bgggggg {
    position: relative;

}

.home-about-us .light-blue-bggg {
    background: #EEF4FD;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 10px 0px 0px 0px;
}

.home-about-us .light-blue-bggg .white-bggg {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    top: -16px;
    left: 7%;
    padding: 20px;
}

.home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
    width: 80px;
}

.home-about-us .light-blue-bggg .white-bggg .blkkkk-border {
    border-right: 3px solid;
}

.home-about-us .isi-txttt {
    font-size: 25px;
    color: #3B3B3B;
    font-weight: 700;
}

.home-about-us .certi-txttt {
    font-size: 18px;
    color: #224299;
    font-weight: 700;
}

/*  Media*/

@media(min-width:0px) and (max-width:380px) {
    .home-common-title {
        font-size: 22px;
    }

    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 34px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: 7px;
        left: 2%;
    }

    .home-about-us .isi-txttt {
        font-size: 20px;
    }

    .sub-txtttt-spring {
        font-size: 18px;
    }

    .home-about-us {
        margin: 10% 0;
    }
}

@media(min-width:381px) and (max-width:576px) {
    .home-common-title {
        font-size: 25px;
    }

    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 34px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: 3px;
        left: 2%;
    }

    .home-about-us .isi-txttt {
        font-size: 20px;
    }

    .sub-txtttt-spring {
        font-size: 18px;
    }

    .home-about-us {
        margin: 10% 0;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .home-common-title {
        font-size: 25px;
    }

    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 34px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: 3px;
        left: 2%;
    }

    .home-about-us .isi-txttt {
        font-size: 20px;
    }

    .sub-txtttt-spring {
        font-size: 18px;
    }

    .home-about-us {
        margin: 10% 0;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .home-common-title {
        font-size: 27px;
    }

    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 65px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: -5px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 60px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: -6px;
        left: 3%;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .home-about-us .light-blue-bggg .white-bggg .iso-mark-png {
        width: 60px;
    }

    .home-about-us .light-blue-bggg .white-bggg {
        top: -6px;
        left: 3%;
    }
}