.serviceinner_section {
  padding-bottom: 50px;
}

.serviceinner_section .first-blog-service {
  position: sticky;
  top: 115px;
}

.serviceinner_section .innerimg_div {
  height: 409px;
  width: 100%;
  margin-bottom: 50px;
}

.serviceinner_section .innerimg_div .inner_imgs {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.serviceinner_section .subimg_div {
  height: 268px;
  width: 100%;
}

.serviceinner_section .sub_imgs {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.serviceinner_section .qualityas {
  padding: 50px 0px;
}

.serviceinner_section .play_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eef4fd 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 15px;
}

.serviceinner_section .text-end.sub-txt {
  color: #224299;
}

.serviceinner_section .circleicn {
  color: #eef4fd;
  background-color: black;
  border-radius: 50%;
  font-size: 20px;
}

.serviceinner_section .pdf_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EEF4FD 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 15px;
  border-left: 2px solid #224299;
}

.rightpdf_div .sub-txt {
  color: #224299;
}

.serviceinner_section .pdficn {
  font-size: 50px;
  color: #224299;
}


@media (min-width:0px) and (max-width:576px) {
  .serviceinner_section .first-blog-service {
    position: unset;
  }
}

@media (min-width:577px) and (max-width:768px) {
  .serviceinner_section .first-blog-service {
    position: unset;
  }
}