.servicing-across {
    margin: 5% 0;
    background: #EEF4FD;
    padding: 60px 60px 90px 60px;
}

.servicing-across .double-txttt {
    font-weight: 700;
}

.servicing-across .white-bgggg {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 11px;
    padding: 25px;
    position: relative;
    z-index: 999;
}

.servicing-across .form-label {
    font-size: 15px;
    color: #3B3B3B;
    font-weight: 700;
}

.servicing-across .form-control {
    font-size: 14px;
    color: #8D8D8D;
    height: 45px;
}

.servicing-across .form-control:focus {
    box-shadow: none;

}

.servicing-across .branch-main {
    margin-top: 45%;
}

.servicing-across .branch-main .branch-name {
    font-size: 28px;
    color: #BFBFBF;
    font-weight: 600;
}

.servicing-across:hover .branch-main .branch-name {
    font-size: 28px;
    color: #000;
}

.servicing-across .servicing-main-imggg {
    position: relative;
}

.servicing-across .servicing-main-imggg .ring-image {
    width: 64%;
    transition: transform 0.5s ease;
    transform: rotate(-135deg);
    position: absolute;
    top: 122px;
    left: 89px;
}

.servicing-across:hover .servicing-main-imggg .ring-image {
    transform: rotate(25deg);
}

.servicing-across .servicing-main-imggg .bottle-image {
    width: 80%;
    position: absolute;
    top: -47px;
    left: 189px;
    transition: left 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.servicing-across:hover .servicing-main-imggg .bottle-image {
    left: 155px;
}


/* media */

@media(min-width:0px) and (max-width:576px) {
    .servicing-across .servicing-main-imggg {
        display: none;
    }

    .servicing-across .branch-main {
        margin-top: 4%;
    }

    .servicing-across:hover .branch-main .branch-name {
        font-size: 18px;
    }

    .servicing-across .branch-main .branch-name {
        font-size: 18px;
    }

    .servicing-across {
        padding: 27px 10px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .servicing-across .servicing-main-imggg {
        display: none;
    }

    .servicing-across .branch-main {
        margin-top: 4%;
    }

    .servicing-across:hover .branch-main .branch-name {
        font-size: 20px;
    }

    .servicing-across .branch-main .branch-name {
        font-size: 20px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .servicing-across .servicing-main-imggg {
        display: none;
    }

    .servicing-across .branch-main {
        margin-top: 4%;
    }

    .servicing-across:hover .branch-main .branch-name {
        font-size: 22px;
    }

    .servicing-across .branch-main .branch-name {
        font-size: 22px;
    }
}

@media(min-width:993px) and (max-width:1440px) {
    .servicing-across:hover .branch-main .branch-name {
        font-size: 24px;
    }

    .servicing-across .branch-main .branch-name {
        font-size: 24px;
    }
}