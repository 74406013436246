.find-us {
    background: #EEF4FD;
    padding: 25px 0px 50px 0px;
    margin: 5% 0;
    position: relative;
}

.find-us .place-order-form {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px #00000029;
    border-radius: 11px;
    padding: 30px 15px 15px 15px;
}

.find-us .place-order-form .place-order-txtt {
    text-align: center;
    font-weight: 600;
}

.find-us .place-order-form .form-label {
    font-size: 15px;
    color: #3B3B3B;
    font-weight: 500;
}

.find-us .place-order-form .form-control {
    font-size: 14px;
    color: #8D8D8D;
    height: 46px;
}

.find-us .place-order-form .form-control:focus {
    box-shadow: none;
}

.find-us .white-bg-hover {
    padding: 15px;
}

.find-us .white-bg-hover:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    padding: 15px;
}

.find-us .white-bg-hover .address {
    font-size: 14px;
    color: #000;
}

.find-us .white-bg-hover .mail-add {
    color: #224299;
    font-size: 14px;
}

.find-us .white-bg-hover .iconnnnn {
    font-size: 16px;
    color: #000;
}

.find-us .white-bg-hover .location-head {
    font-size: 28px;
    font-weight: bold;
    color: #000000;
}

.find-us .main-img-classsss {
    position: relative;
}

.find-us .man-image {
    width: 18%;
    height: auto;
    position: absolute;
    right: 0;
    bottom: -157px;
}

.find-us .branchname {
    font-size: 16px;
    font-weight: 500;
}


@media(min-width:0px) and (max-width:576px) {
    .find-us .white-bg-hover:hover {
        margin-top: 10%;
    }

    .find-us .white-bg-hover .location-head {
        font-size: 24px;
    }

    .find-us .white-bg-hover .address {
        font-size: 13px;
    }

    .find-us .man-image {
        display: none;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .find-us .white-bg-hover .location-head {
        font-size: 24px;
    }

    .find-us .white-bg-hover .address {
        font-size: 13px;
    }

    .find-us .man-image {
        display: none;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .find-us .white-bg-hover .location-head {
        font-size: 24px;
    }

    .find-us .white-bg-hover .address {
        font-size: 13px;
    }

    .find-us .man-image {
        width: 29%;
        bottom: -89px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .find-us .man-image {
        width: 29%;
        bottom: -102px;
    }
}

@media(min-width:1201px) and (max-width:1440px) {
    .find-us .man-image {
        width: 25%;
        bottom: -122px;
    }
}

@media(min-width:1441px) and (max-width:2000px) {
    .find-us .man-image {
        display: none;
    }
}

@media(min-width:2500px) and (max-width:3000px) {
    .find-us .man-image {
        width: 22%;
        bottom: -181px;
    }
}