 .puritycard_section .wtrmain_div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    opacity: 1;
    padding: 15px;
    height: 210px;
}
.puritycard_section   .wtrmain_div .sub-txt{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    color: #3B3B3B;
}
.puritycard_section   .wtrmain_div .midtext{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    
}

.puritycard_section  .wtrimmg{
    width: 66px;
height: 61px;
background: #DCF4FE 0% 0% no-repeat padding-box;
opacity: 1;
display: flex;
align-items: center;
justify-content: center;
padding: 15px 0px;
}
.puritycard_section .wtrimmg .war_img{
    height: 100%;
    width: auto;
max-width: 100%;
object-fit: contain;
}
