.how-it-works {
    margin: 5% 0;
}

.how-it-works .video-div .video-works {
    width: 100%;
}

.how-it-works .video-div {
    margin: 3% 0;
}

@media (min-width:0px) and (max-width:767px) {
    .how-it-works {
        margin: 10% 0;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .how-it-works .video-div .video-works {
        width: 83%;
    }
}

@media (min-width:1025px) and (max-width:1399px) {
    .how-it-works .video-div .video-works {
        width: 83%;
    }
}