.footer {
    background: #224299;
    padding-top: 79px;

}

.footer .footerlogo {
    width: auto;
    height: 100px;
}

.footer .nav {
    margin-top: 17px;
}

.footer .nav-link {
    color: #FFF;
    font-size: 14px;
    margin: 0px 11px;
}

.footer .nav-link:hover {
    color: #FFF;
    font-size: 14px;
    margin: 0px 11px;
}

.footer .nav-link.active {
    color: #FFF;
    font-size: 14px;
    margin: 0px 11px;
}

.footer .nav-link:focus {
    color: #FFF;
    font-size: 14px;
    margin: 0px 11px;
}

.footer a {
    color: #fff;
}

.footer .text-area-loaction {
    margin-top: 20px;
}

.footer p {
    font-size: 12px;
    color: #FFF;
}

.footer .email-box .form-control {
    border-radius: 0%;
    padding: 0px;
    border: none;
    font-size: 15px;
    color: #000;
    background-color: transparent;
    padding-left: 10px;
}

.footer .email-box .form-control::placeholder {
    font-size: 15px;
    color: #000;
}

.footer .email-box .form-control:focus {
    box-shadow: none;
}

.footer .input-group-text {
    font-size: 15px;
    color: #000;
    background: #F2DD34;
    font-weight: 700;
}

.footer .input-group>.form-control {
    font-size: 14px;
    color: #8D8D8D;
}

/* footer banner css start */

.footer-bannermain {
    position: relative;
}


.footer .footer-head-title {
    font-size: 20px;
    font-weight: 700;
    border-left: 4px solid #F2DD34;
    color: #FFF;
    padding: 6px 10px;
}

.footer .address-holder .text-holder {
    margin: 23px 0px;
}

.footer .address-holder .text-holder p {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0%;
}

.footer .address-holder .social-icon {
    margin-left: auto;
    margin-top: 34px;
}

.footer .address-holder .social-icon .sociallogo {
    width: 17px;
    height: 17px;
    margin: 30px 5px 0px 5px;
}

.footer .copyright-holder {
    border-top: 1px solid #fff;
    margin-top: 2%;
}

.footer .copyright-holder p {
    color: #fff;
    font-size: 13px;
    margin: 25px 0px;
}


.footer .location-holder {
    margin-top: 26px;

}

.footer .location-holder .call-holder span {
    color: #FFF;
    font-size: 14px;
}

.footer .location-holder .call-holder .call-icon {
    color: #FFF;
}

@media(min-width:992px) and (max-width:1200px) {
    .footer .nav-link:hover {
        margin: 0px 4px;
    }

    .footer .nav-link.active {
        margin: 0px 4px;
    }

    .footer .nav-link {
        margin: 0px 4px;
    }

    .footer .nav-link:focus {
        margin: 0px 4px;
    }
}

@media(min-width:769px) and (max-width:991px) {
    .footer .nav-link:hover {
        margin: 0px 4px;
    }

    .footer .nav-link.active {
        margin: 0px 4px;
    }

    .footer .nav-link {
        margin: 0px 4px;
    }

    .footer .nav-link:focus {
        margin: 0px 4px;
    }

    .footer .nav {
        margin-top: 46px;
    }

    .footer .address-holder .social-icon {
        margin-top: 19px;
        margin-bottom: 16px;
    }
}

@media(min-width:0px) and (max-width:768px) {
    .footer .nav-link:hover {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link.active {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav-link:focus {
        margin: 0px 0px;
        padding: 0px 6px;
        font-size: 13px;
    }

    .footer .nav {
        margin-top: 9px;
        margin-bottom: 19px;
    }

    .footer .address-holder .social-icon {
        margin-top: 19px;
        margin-bottom: 16px;
    }

    .footer .location-holder {
        display: unset;
    }

    .footer {
        padding-top: 27px;
    }

    .footer .footerlogo {
        height: 64px;
    }

    .footer .footer-head-title {
        font-size: 18px;
        padding: 1px 8px;
        margin-top: 9%;
    }

    .footer .location-holder .call-holder span {
        font-size: 12px;
    }

    .footer .input-group-text {
        font-size: 13px;
    }

}