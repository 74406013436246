.waterrrrr {
    margin: 0 0 5% 0;
}

.water-composition {
    transition: transform 0.5s ease;
}

.water-composition .water-compo {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    transition: transform 0.5s ease;
}

.water-composition:hover .water-compo {
    display: none;
}

.water-composition .water-compo-with-hover {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: none;
    transition: transform 0.5s ease;
}

.water-composition:hover .water-compo-with-hover {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
}


.waterrrrr {
    color: #FFF;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    position: relative;
    overflow: hidden;
}

.waterrrrr::before {
    content: '';
    position: absolute;
    /* top: 130px; */
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../../../../public/assets/Image/Homepage/Water-Composition/bg.png');
    z-index: 1;
    transform: scaleX(0);
    transform-origin: top;
    transition: transform 0.5s ease-out;
}

.waterrrrr:hover::before {
    transform: scaleX(1);
    transform-origin: top;
}

.waterrrrr .water-composition {
    position: relative;
    z-index: 2;
}

/* media */

@media(min-width:0px) and (max-width:576px) {
    .waterrrrr::before {
        top: 0px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .waterrrrr::before {
        top: 0px;
    }
}

@media(min-width:768px) and (max-width:1024px) {
    .waterrrrr::before {
        top: 60px;
    }
}

@media(min-width:1025px) and (max-width:1399px) {
    .waterrrrr::before {
        top: 90px;
    }
}