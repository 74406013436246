.galleryvideo .video-works {
    width: 100%;
}


.galleryvideo .silder-btn {
    position: relative;

}

.galleryvideo .silder-btn .back-btn {
    position: absolute;
    top: -157px;
    z-index: 999;
    left: 0;
}

.galleryvideo .silder-btn .next-btn {
    position: absolute;
    top: -157px;
    z-index: 999;
    right: 0;
}

.galleryvideo .silder-btn .icon-btnnn {
    color: #224299;
    font-weight: bold;
    font-size: 28px;
    background: #FFF;
    border: 3px solid #224299;
    border-radius: 50%;
    padding: 4px 9px;
}

.galleryvideo .silder-btn .back-btn:hover .icon-btnnn {
    border: 5px solid #224299;
    font-size: 25px;
}

.galleryvideo .silder-btn .next-btn:hover .icon-btnnn {
    border: 5px solid #224299;
    font-size: 25px;
}

@media(min-width:0px) and (max-width:420px) {
    .galleryvideo .silder-btn .back-btn {
        top: -117px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -117px;
    }
}

@media(min-width:421px) and (max-width:576px) {
    .galleryvideo .silder-btn .back-btn {
        top: -152px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -152px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .galleryvideo .silder-btn .back-btn {
        top: -108px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -108px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .galleryvideo .silder-btn .back-btn {
        top: -108px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -108px;
    }
}

@media(min-width:992px) and (max-width:1024px) {
    .galleryvideo .silder-btn .back-btn {
        top: -118px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -118px;
    }
}

@media(min-width:1025px) and (max-width:1399px) {
    .galleryvideo .silder-btn .back-btn {
        top: -118px;
    }

    .galleryvideo .silder-btn .next-btn {
        top: -118px;
    }
}