.purity_section{
    padding: 50px 0px;
    margin: 5% 0;
}



.purity_section  .watrimgdiv{
    height: 435px;
box-shadow: 0px 0px 10px #00000029;
opacity: 1;
}

.purity_section  .watrimgdiv .watr_img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

