.get-in-touch {
    margin: 5% 0%;
}

.get-in-touch .white-bgggg {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 20px;
    padding: 10px 20px;
    height: 222px;
}

.get-in-touch .white-bgggg .double-txtt {
    font-weight: 700;
}

.get-in-touch .white-bgggg .sub-double-txtt {
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* -webkit-line-clamp: 2; */
}

.get-in-touch .white-bgggg .font-ggfsjz .font-icon-main {
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
}

.get-in-touch .white-bgggg:hover .font-ggfsjz .font-icon-main {
    background: #224299;
    border: 1px solid #224299;
    transition: 0.5s;
}

.get-in-touch .white-bgggg .font-ggfsjz {
    display: flex;
    justify-content: center;

}

.get-in-touch .white-bgggg .font-ggfsjz .font-icon-main .font-icon {
    color: #224299;
    background: #DCF4FE;
    padding: 9px;
    border-radius: 50%;
    font-size: 32px;
}

.get-in-touch .white-bgggg:hover .font-ggfsjz .font-icon-main .font-icon {
    color: #FFF;
    background: #224299;
    font-size: 32px;
    transition: 0.5s;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .get-in-touch .white-bgggg {
        margin-bottom: 8%;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .get-in-touch .white-bgggg {
        margin-bottom: 8%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .get-in-touch .white-bgggg {
        margin-bottom: 8%;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .get-in-touch .white-bgggg {
        height: 244px;
    }
}