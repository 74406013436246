.whychoose_section {
    margin: 5% 0;
}

.whychoose_section .title_divs {
    text-align: center;
    padding: 20px 0px;
}

@media(min-width:0px) and (max-width:576px) {
    .whychoose_section .title_divs {

        padding: 10px 0px;
    }
}