.leading_section {
  margin: 5% 0;
  transition: 0.8s ease;
}

.imgblue_div {
  position: relative;
}

.leading_section .bootle_img_div {
  width: 100%;
  padding: 20px;
  height: 500px;
  position: absolute;
  top: 0;
}

.leading_section .bootle_img_div .bootles_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.leading_section .blue-bg {
  background: #266db9;
  height: 500px;
  width: 67px;
}

.leading_section .btltexts {
  font-size: 62px;
  font-weight: bold;
  letter-spacing: 0.46px;
  color: #858585;
}

.leading_section:hover .btltexts {
  color: #fff;
}

.leading_section .main-border {
  margin-top: 25%;
  border-left: 3px solid #266db9;
  transition: border-left 0.3s ease, padding 0.3s ease;
}

/* new */

.leading_section .light-blue-bgggg {
  background: #f7fcfe;
  width: 100%;
  height: 100%;
  padding: 45px;
  position: relative;
  overflow: hidden;
  transition: background 0.5s ease;
}

.leading_section:hover .light-blue-bgggg {
  background: #266db9;
}

.leading_section .light-blue-bgggg::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #266db9;
  transform: scaleX(0);
  transform-origin: top right;
  transition: transform 0.5s ease-out;
}

.leading_section:hover .light-blue-bgggg::before {
  transform: scaleX(1);
}

.leading_section .light-blue-bgggg .common-light {
  position: relative;
  z-index: 2;
}

.leading_section:hover .main-border {
  border-left: 3px solid #f2dd34;
  padding: 7% 0 0 1px;
}

.leading_section .main-border .white-bg {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  text-align: center;
}

.leading_section .main-border .white-bg p {
  font-size: 18px;
  font-weight: 700;
  color: #3b3b3b;
  margin-bottom: 0;
}

@media (min-width: 0px) and (max-width: 575px) {
  .leading_section .light-blue-bgggg {
    padding: 20px;
  }

  .leading_section .btltexts {
    font-size: 31px;
  }

  .leading_section .main-border {
    margin-top: 20px;
    border-left: 3px solid #266db9;
    transition: border-left 0.3s ease, padding 0.3s ease;
  }

  .leading_section .bootle_img_div {
    height: 360px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .leading_section .light-blue-bgggg {
    padding: 20px;
  }

  .leading_section .btltexts {
    font-size: 31px;
  }

  .leading_section .main-border {
    margin-top: 20px;
    border-left: 3px solid #266db9;
    transition: border-left 0.3s ease, padding 0.3s ease;
  }

  .leading_section .bootle_img_div {
    height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .leading_section:hover .main-border {
    padding: 2% 0 0 1px;
  }

  .leading_section .light-blue-bgggg {
    padding: 20px;
  }

  .leading_section .btltexts {
    font-size: 31px;
  }

  .leading_section .main-border {
    margin-top: 20px;
    border-left: 3px solid #266db9;
    transition: border-left 0.3s ease, padding 0.3s ease;
  }

  .leading_section .bootle_img_div {
    height: 450px;
  }

  .leading_section .main-border .white-bg {
    padding: 17px 10px 1px 10px;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .leading_section .blue-bg {
    display: none;
  }

  .leading_section .bootle_img_div {
    position: unset;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .leading_section .btltexts {
    font-size: 48px;
  }

  .leading_section .main-border .white-bg p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .leading_section .btltexts {
    font-size: 48px;
  }

  .leading_section .main-border .white-bg p {
    font-size: 15px;
  }
}