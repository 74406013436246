

.main-ceritification {
    margin: 5% 0;
}

.certification {
    background: #EEF4FD;
    padding: 0% 0 3% 0;
}

.certification .white-bggg {
    background: #FFF;
    padding: 5%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    margin-top: 10%;
    position: relative;
    z-index: 999;
    width: 618px;
}

.certification .about-us-divvvv {
    position: relative;

}

.certification .white-bggg .isi-txttt {
    font-size: 20px;
    color: #3B3B3B;
    font-weight: 700;
    margin-top: 6%;
}


.certification .cer-main-img .girl-img {
    position: absolute;
    left: 43%;
    top: 21%;
    transition: left 0.3s ease;
    height: 420px;
}

.certification:hover .cer-main-img .girl-img {
    left: 46%;
}

.certification .mark-img {
    width: 50%;
}




/* Media */

@media(min-width:0px) and (max-width:320px) {
    .certification .white-bggg .isi-txttt {
        font-size: 16px;
    }

    .certification .cer-main-img {
        position: unset;
    }

    .certification .cer-main-img .girl-img {
        display: none;
    }

    .certification .white-bggg {
        padding: 15%;
        width: 100%;
    }
}

@media(min-width:321px) and (max-width:576px) {
    .certification .white-bggg .isi-txttt {
        font-size: 16px;
    }

    .certification .cer-main-img {
        position: unset;
    }

    .certification .cer-main-img .girl-img {
        display: none;
    }

    .certification .white-bggg {
        padding: 15%;
        width: 100%;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .certification .white-bggg .isi-txttt {
        font-size: 16px;
    }

    .certification .cer-main-img {
        position: unset;
    }

    .certification .cer-main-img .girl-img {
        display: none;
    }

    .certification .white-bggg {
        padding: 15%;
        width: 100%;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .certification .white-bggg {
        padding: 5%;
        width: 405px;
    }

    .certification:hover .cer-main-img .girl-img {
        left: 54%;
    }

    .certification .cer-main-img .girl-img {
        left: 56%;
        top: 21%;
        height: 287px;
    }
}

@media(min-width:769px) and (max-width:991px) {
    .certification .white-bggg {
        width: 403px;
    }

    .certification:hover .cer-main-img .girl-img {
        left: 55%;
        height: 279px;
    }

    .certification .white-bggg {
        width: 403px;
    }

    .certification .cer-main-img .girl-img {
        left: 54%;
        top: 19%;
        height: 294px;
    }
}

@media(min-width:992px) and (max-width:1024px) {
    .certification .white-bggg {
        width: 527px;
    }

    .certification .cer-main-img .girl-img {
        left: 50%;
        top: 20%;
        height: 333px;
    }

    .certification:hover .cer-main-img .girl-img {
        left: 53%;
    }

}

@media(min-width:1025px) and (max-width:1399px) {

    .certification .white-bggg {
        width: 496px;
    }

    .certification .cer-main-img .girl-img {
        height: 345px;
    }

    .certification:hover .cer-main-img .girl-img {
        left: 42%;
    }
}