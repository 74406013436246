.main-blog {
    margin: 2% 0% 5% 0;
}

.main-blog .main-img-blog {
    position: relative;
}

.main-blog .blog-img {
    width: 100%;
}

.main-blog .main-img-blog .white-bggg {
    position: absolute;
    bottom: -12px;
    left: 2%;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;
    padding: 5px 10px;
}