.team {
    margin: 5%;

}

.team .sub-txt-team {
    font-size: 16px;
    color: #3B3B3B;
}

.team .team-img {
    width: 100%;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
}

.team .main-img-team {
    position: relative;
}

.team .main-img-team .team-name {
    font-size: 18px;
    font-weight: 700;
    color: #3B3B3B;
    margin-bottom: 0;
}

.team .main-img-team:hover .team-name {
    color: #FFF;
}

.team .main-img-team .common-txt-posss {
    color: #000;
    font-size: 14px;
}

.team .main-img-team:hover .common-txt-posss {
    color: #fff;
    font-size: 14px;
}

.team .main-img-team:hover .center-pos {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.team .main-img-team {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 10px #00000029;
}

.team .main-img-team .overlay {
    position: absolute;
    bottom: 0;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFFCC 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    color: #000;
    width: 100%;
    transition: bottom 0.5s ease, height 0.5s ease;
    opacity: 1;
    padding: 80px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 0;
}

.team .main-img-team:hover .overlay {
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    transition: bottom 0.5s ease, height 0.5s ease;
    opacity: 1;
    font-size: 20px;
    text-align: center;
    height: 100%;
    border-radius: 10px;
}

.team .main-img-team .social-icon-img {
    width: 23px;
    margin-right: 2px;
    display: none;
}

.team .main-img-team:hover .social-icon-img {
    display: block;
}

@media(min-width:0px)and (max-width:576px) {
    .team .sub-txt-team {
        font-size: 14px;
    }
}

@media(min-width:577px)and (max-width:768px) {
    .team .sub-txt-team {
        font-size: 14px;
    }
}