.gallery {
    margin: 5%;
}

.gallery .gallery-img {
    width: 100%;
}


.gallery .swiper {
    width: 100%;
    height: 709px;
    margin-left: auto;
    margin-right: auto;
}

.gallery .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((100% - 30px) / 2) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.gallery .swiper-imggg {
    width: 100%;
}

.gallery .swiper-first-imggg {
    width: 100%;
    aspect-ratio: 3/4;
    height: 100%;
}

.gallery .swiper-pagination-bullet {
    width: 18px;
    height: 9px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.gallery .swiper-pagination-bullet-active {
    width: 59px;
    background: #224299;
}


/* new css */

.gallery .video-works {
    width: 100%;
}


/* Media */

@media(min-width:0px) and (max-width:380px) {
    .gallery .swiper-first-imggg {
        aspect-ratio: unset;
    }

    .gallery .swiper {
        height: 247px;
    }

    .gallery .swiper-pagination-bullet-active {
        width: 26px;
    }

    .gallery .swiper-pagination-bullet {
        width: 11px;
        height: 5px;
    }
}

@media(min-width:381px) and (max-width:576px) {
    .gallery .swiper-first-imggg {
        aspect-ratio: unset;
    }

    .gallery .swiper {
        height: 350px;
    }

    .gallery .swiper-pagination-bullet-active {
        width: 28px;
    }

    .gallery .swiper-pagination-bullet {
        width: 15px;
        height: 7px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .gallery .swiper-first-imggg {
        aspect-ratio: unset;
    }

    .gallery .swiper {
        height: 485px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .gallery .swiper-first-imggg {
        aspect-ratio: unset;
    }

}


@media(min-width:993px) and (max-width:1024px) {
    .gallery .swiper {
        height: 483px;
    }
}

@media(min-width:1025px) and (max-width:1200px) {
    .gallery .swiper {
        height: 497px;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .gallery .swiper {
        height: 600px;
    }
}