.objectives .objectives-title {
    color: #224299;
    opacity: 0.15;
    font-size: 130px;
    font-weight: bold;
}

.objectives .sub-txt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

.objectives .objective-main {
    margin: 10% 0;
}

.objectives .objective-main:hover .objectives-title {
    color: #224299;
    opacity: 0.35;
}

.objectives .objective-main .obj-main-img {
    /* overflow: hidden; */
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.objectives .objective-main .obj-main-img .obj-img {
    position: relative;
    background: no-repeat;
    width: 100%;
    display: block;
    transition: 0.5s;
}

.objectives .objective-main:hover .obj-main-img .obj-img {
    transform: scale(1.1);
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .objectives .objectives-title {
        font-size: 35px;
        text-align: center;
    }

    .objectives .objective-main .obj-main-img .obj-img {
        width: 100%;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .objectives .objectives-title {
        font-size: 40px;
    }

    .objectives .objective-main .obj-main-img .obj-img {
        width: 100%;
        height: 267px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .objectives .objectives-title {
        font-size: 42px;
    }

    .objectives .objective-main .obj-main-img .obj-img {
        width: 100%;
        height: 267px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .objectives .objectives-title {
        font-size: 42px;
    }

    .objectives .objective-main .obj-main-img .obj-img {
        width: 100%;
        height: 267px;
    }
}